import { useEffect, useState } from "react";
import { OfficePicturesS } from "../../services/DoctorProfile";
import CustomSuccessModal from "../../../common/components/customSuccessModal/CustomSuccessModal";
import CustomRedAlert from "../../../common/components/customRedAlert/CustomRedAlert";

export default function OfficePictures() {
  const [images, setImages] = useState([]);
  const [imagesUpdated, setImagesUpdated] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successBody, setSuccessBody] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const _createSuccessMessage = (message) => {
    setShowSuccessModal(true);
    setSuccessBody(message);
  };

  const onCloseSuccessMessage = () => {
    setShowSuccessModal(false);
    setSuccessBody('');
  };

  useEffect(() => {
    setSuccessBody('');
    setErrorMessage('');
    setImagesToDelete([]);
    setImages([]);
  }, []);

  useEffect(() => {
    // Fetch office pictures on component mount
    OfficePicturesS.getAllPictures()
      .then(response => {
        console.log(response.data.pictures, 'response');
        setImages(response.data.pictures);
      })
      .catch(error => {
        console.error("Error fetching office pictures:", error);
      });
  }, []);

  useEffect(() => {
    const areImagesUpdated = images.filter(image => typeof image !== 'string').length > 0 || imagesToDelete.length > 0;
    if (areImagesUpdated) {
        setImagesUpdated(areImagesUpdated);
    } else {
        setImagesUpdated(false);
    }
  }, [images, imagesToDelete]);

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    if (images.length + files.length > 10) {
      alert("You can only upload up to 10 images.");
      return;
    }
    setImages([...images, ...files]);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    const deletedImage = images[index];
    if (!imagesToDelete.includes(deletedImage) && typeof deletedImage === 'string') {
        setImagesToDelete([...imagesToDelete, deletedImage]);
    }
    setImages(updatedImages);
  };

  const saveChanges = () => {
    setIsUpdating(true);
    console.log(images, 'images');
    console.log(imagesToDelete, 'imagesToDelete');

    const files = images.filter(image => typeof image !== 'string');
    if (images.length > 10) {
      setErrorMessage('Solo puedes subir hasta 10 imágenes.');
      return;
    }

    const formData = new FormData();
    files.forEach(file => {
      formData.append("files", file);
    });

    if (imagesToDelete.length) {
      imagesToDelete.forEach(file => {
        formData.append("deleteFiles", file);
      })
    }

    OfficePicturesS.uploadPictures(formData)
      .then(response => {
        console.log(response, 'response');
        setImages(response.data.pictures);
        setImagesUpdated(false);
        setImagesToDelete([]);
        setIsUpdating(false);
        _createSuccessMessage('Imágenes actualizadas exitosamente');
      })
      .catch(error => {
        setErrorMessage('Ocurrió un error al subir las imágenes.');
      });
  };

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 side-bar-right bg-dl-screen-lightblue">
      <div className="w-full min-h-screen bg-blue-100 flex flex-col items-center p-6">
        <h2 className="text-2xl font-semibold mb-3">Fotos del Consultorio</h2>
        <p className="text-gray-700 text-lg mb-6 text-center max-w-lg">
          Para agregar o cambiar sus fotos del consultorio, por favor haga clic en el siguiente botón. 
          Deben ser fotos con una buena resolución y máximo 10 fotos.
        </p>

        {/* Upload Button */}
        <label className="cursor-pointer bg-white border border-gray-300 rounded-full px-6 py-3 text-gray-700 text-lg mb-6">
          Seleccionar fotos
          <input type="file" multiple accept="image/*" className="hidden" onClick={(event) => event.target.value = null} onChange={handleImageUpload} />
        </label>

        {/* Image Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 w-full max-w-5xl">
          {images.length ?  images.map((image, index) => (
            <div
              key={index}
              className="w-40 h-40 bg-gray-300 rounded-lg flex flex-col items-center justify-between relative"
            >
              {/* Delete Button (Now in a proper position) */}
              <div className="w-7 h-7 bg-red-600 text-white rounded-full flex items-center justify-center text-sm hover:bg-red-700 shadow-md absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 z-10">
                <button
                  onClick={() => handleDeleteImage(index)}
                  className="w-6 h-6 bg-red-600 text-white rounded-full flex items-center justify-center text-xs hover:bg-red-700"
                >
                  ✕
                </button>
              </div>

              {/* Image */}
              <img src={typeof image === 'string' ? process.env.REACT_APP_MEDIA_SERVER+'/'+image : URL.createObjectURL(image)} alt="Uploaded" className="w-full h-full object-cover" />
            </div>
          )) : null}
        </div>

        {/* Show the button only when images are uploaded */}
        {imagesUpdated && (
          <button onClick={() => saveChanges()} className="mt-6 px-8 py-3 bg-blue-500 text-white text-lg rounded-lg" disabled={isUpdating}>
            Actualizar
          </button>
        )}
      </div>
      {showSuccessModal && <CustomSuccessModal title={'Acción ejecutada con éxito!'} bodyText={successBody} buttonText={'Cerrar'} buttonAction={onCloseSuccessMessage} open={showSuccessModal} onClose={onCloseSuccessMessage} />}
      {Boolean(errorMessage) && (
                <CustomRedAlert
                    open={true}
                    title={`Ocurrió un error.`}
                    bodyText={errorMessage}
                    onClose={() => setErrorMessage(null)}
                />
            )}
    </div>
  );
}

