/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid';
import { Tab } from '@headlessui/react';
import { Link } from 'react-router-dom';
import useDoctorResults from '../../hooks/useDoctorResults';
import ItemCard from '../itemCard/ItemCard';
import { BriefcaseIcon, LocationMarkerIcon } from '@heroicons/react/outline';

import Loader from '../../../v2/components/Loader';
import Perfil from './Perfil';
import Horarios from './Horarios';
import Tarifas from './Tarifas';
import Info from './Info';
import configurations from '../../../common/utils/configurations';
import AvailableUntil from './AvailableUntil';
import { format } from 'date-fns';
import Services from './Services';
import Photos from './Photos';

const APP_SERVER = process.env.REACT_APP_API_SERVER;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const MapIcon = () => (
  <figure className="map-icon">
    <svg width="33" height="46" viewBox="0 0 33 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5003 24.9555C20.9554 24.9555 24.5669 21.2867 24.5669 16.7611C24.5669 12.2355 20.9554 8.5668 16.5003 8.5668C12.0452 8.5668 8.43359 12.2355 8.43359 16.7611C8.43359 21.2867 12.0452 24.9555 16.5003 24.9555Z" fill="#00B3FF" />
      <path
        d="M33 16.7611C33 7.50526 25.6117 0 16.5 0C7.38833 0 0 7.50526 0 16.7611C0 24.3893 11.2017 39.1689 15.147 44.1377H6.05C5.544 44.1377 5.13333 44.5548 5.13333 45.0688C5.13333 45.5828 5.544 46 6.05 46H16.1333C16.61 46 16.9987 45.6275 17.0427 45.1508C19.888 41.6384 33 25.0113 33 16.7611ZM16.5 1.86235C24.5887 1.86235 31.1667 8.54445 31.1667 16.7611C31.1667 23.1453 21.604 36.476 16.5 42.8899C11.396 36.476 1.83333 23.1453 1.83333 16.7611C1.83333 8.54445 8.41133 1.86235 16.5 1.86235Z"
        fill="black"
      />
    </svg>
  </figure>
);

export const EducaciónIcon = () => (
  <figure className="education-icon">
    <svg width="42" height="35" viewBox="0 0 42 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.4702 9.72975L21.6634 0.0988769C21.4152 -0.0232707 21.1241 -0.0326667 20.8664 0.075387L15.855 2.18948C15.373 2.39619 15.1439 2.94586 15.3539 3.42505C15.5591 3.89955 16.1223 4.12505 16.6091 3.91834L21.2148 1.97337L38.9073 10.5754L21 19.3841L3.07841 10.566L11.7505 6.23915L21.0095 11.3881C21.4725 11.6465 22.05 11.4821 22.3125 11.0311C22.5702 10.5801 22.408 10.0069 21.9498 9.74854L12.2468 4.35525C11.97 4.20022 11.6311 4.19082 11.3448 4.33646L0.525 9.73445C0.200455 9.89418 0 10.2183 0 10.5707C0 10.9277 0.205227 11.2519 0.529773 11.4069L7.63636 14.9116V28.423C7.63636 28.6814 7.42159 28.8928 7.15909 28.8928C6.89659 28.8928 6.68182 29.1042 6.68182 29.3626V34.0888C6.68182 34.5915 7.09705 35.0002 7.60773 35.0002H9.555C10.0752 35.0002 10.5 34.5821 10.5 34.07V29.3626C10.5 29.1042 10.2852 28.8928 10.0227 28.8928C9.76023 28.8928 9.54545 28.6814 9.54545 28.423V23.8049C11.8173 25.4257 16.6091 28.423 21 28.423C26.8657 28.423 33.705 23.2787 33.9914 23.0579L34.378 22.7667L34.1393 15.0197L41.4702 11.4116C41.7948 11.2519 42 10.9277 42 10.5707C42 10.2136 41.7948 9.88478 41.4702 9.72975ZM32.4402 21.8506C30.9559 22.9029 25.4816 26.5438 21 26.5438C16.17 26.5438 10.3759 22.1042 9.54545 21.4418V15.8512L20.5752 21.2774C20.7089 21.3432 20.8568 21.3761 21 21.3761C21.1432 21.3761 21.2911 21.3432 21.4248 21.2774L32.2541 15.9452L32.4402 21.8506Z"
        fill="black"
      />
    </svg>
  </figure>
);

export const HorariosIcon = () => (
  <figure className="horarios-icon">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1150_757)">
        <path
          d="M16 1.81818C23.8182 1.81818 30.1818 8.18182 30.1818 16C30.1818 23.8182 23.8182 30.1818 16 30.1818C8.18182 30.1818 1.81818 23.8182 1.81818 16C1.81818 8.18182 8.18182 1.81818 16 1.81818ZM16 0C7.16364 0 0 7.16364 0 16C0 24.8364 7.16364 32 16 32C24.8364 32 32 24.8364 32 16C32 7.16364 24.8364 0 16 0Z"
          fill="black"
        />
        <path
          d="M22.3645 20.3636C22.2191 20.3636 22.07 20.3273 21.9318 20.2545L15.0918 16.5418V4.72727C15.0918 4.22545 15.4991 3.81818 16.0009 3.81818C16.5027 3.81818 16.91 4.22545 16.91 4.72727V15.4582L22.7973 18.6545C23.2373 18.8945 23.4009 19.4473 23.1609 19.8873C22.9973 20.1927 22.6845 20.3636 22.3645 20.3636Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1150_757">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </figure>
);

export const CardWrap = ({ children }) => (
  <section aria-labelledby="applicant-information-title" className="my-6">
    <div className="bg-white shadow  rounded-[24px]">{children}</div>
  </section>
);

export default function DoctorResultsTabsElement() {
  const { doctorData, isLoading, tabs } = useDoctorResults();

  const handleTab = ({ selected }) => (selected ? 'active' : ' outline-none');

  if (isLoading) return <Loader />;
  return (
    <>
      <div className="min-h-full  max-w-7xl mx-auto mt-10">
        <header className="bg-dl-primary-900/[.1] docter-profile rounded-[24px]  pt-40 ">
          <div className="  xl:flex xl:items-center xl:justify-between docter-profile-inner bg-white  px-12">
            <div className="flex-1 min-w-0">
              {/* 
            <nav className="flex" aria-label="Breadcrumb">
               <ol role="list" className="flex items-center space-x-4">
                  <li>
                     <div>
                        <div  className="text-sm font-medium text-gray-500 hover:text-gray-700">
                           {doctorData.speciality}
                        </div>
                     </div>
                  </li>
                  <li>
                     <div className="flex items-center">
                        <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true"/>
                        <div className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                           { doctorData.fullName }
                        </div>
                     </div>
                  </li>
               </ol>
            </nav>
            */}
              {/* <h1 className="mt-2 text-2xl font-bold leading-7 text-dl-accent sm:text-3xl sm:truncate">
               Perfil doctor
            </h1> */}
              {/* Stacked list */}
              <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
                <li>
                  <div className="">
                    <div className="flex items-center  sm:px-0">
                      <div className="min-w-0 flex-1 flex">
                        <div className=" docter-profile-L ">
                          <img className="h-40 w-40 rounded-full group-hover:opacity-75 border-4 border-white	" src={doctorData?.personalImage ? `${APP_SERVER}/${doctorData?.personalImage}` : configurations?.defaultImage} alt="" />
                        </div>
                        <div className="min-w-0 flex-1 px-10 py-7  docter-profile-R flex ">
                          <div className="pr-5 flex-1">
                            <p className="truncate text-2xl text-black font-bold mb-3">{doctorData.fullName}</p>
                            <div className="flex docter-profile-R-inner">
                              <p className="mt-2 flex items-center text-sm mr-5 py-3 px-8 text-black docter-tag bg-sky-50		rounded-full">
                                {/*  <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/> */}
                                <span className="truncate">{doctorData.speciality}</span>
                              </p>
                              <p className="mt-2 flex items-center text-sm mr-5 py-3 px-8 text-black docter-tag bg-sky-50		rounded-full">
                                {/*    <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true"/>   */}
                                <span className="">{doctorData.address}</span>
                              </p>
                            </div>
                          </div>
                          <div className="appointment bg-sky-50 rounded-xl py-4 px-7 flex items-center mr-8 ">
                            <figure>
                              <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M29.1047 25.6119H13.1837C12.3847 25.6119 11.7363 26.2639 11.7363 27.0672C11.7363 27.8704 12.3847 28.5224 13.1837 28.5224H29.1047C29.9037 28.5224 30.5521 27.8704 30.5521 27.0672C30.5521 26.2639 29.9037 25.6119 29.1047 25.6119Z"
                                  fill="black"
                                />
                                <path
                                  d="M38.0558 6.98507H34.8947V1.71134C34.8947 0.90806 34.2463 0.256119 33.4474 0.256119C32.6484 0.256119 32 0.90806 32 1.71134V6.98507H10V1.45522C10 0.65194 9.35158 0 8.55263 0C7.75368 0 7.10526 0.65194 7.10526 1.45522V6.98507H3.94421C1.21737 6.98507 -1 9.28433 -1 12.1075V33.8776C-1 36.7007 1.21737 39 3.94421 39H38.05C40.7768 39 42.9942 36.7007 42.9942 33.8776V12.1075C43 9.28433 40.7826 6.98507 38.0558 6.98507ZM3.94421 9.89552H38.05C39.1789 9.89552 40.0995 10.8851 40.0995 12.1075V16.0075H1.89474V12.1075C1.89474 10.8851 2.81526 9.89552 3.94421 9.89552ZM38.0558 36.0896H3.94421C2.81526 36.0896 1.89474 35.1 1.89474 33.8776V18.9179H40.1053V33.8776C40.1053 35.1 39.1847 36.0896 38.0558 36.0896Z"
                                  fill="black"
                                />
                              </svg>
                            </figure>
                            <figcaption className="pl-5 text-center">
                              <h4 className="text-md text-black font-bold mb-1">Disponibilidad</h4>
                              <p className="text-md">hasta {doctorData.latestAvailablityDate && (doctorData.subscription.toLowerCase() === 'pro' || doctorData.subscription.toLowerCase() === 'plus') ? format( new Date(doctorData.latestAvailablityDate), "dd/MM/yyyy") : "N/A"}</p>
                            </figcaption>
                          </div>
                          {doctorData.id && doctorData.fullName && (doctorData.subscription.toLowerCase() === 'pro' || doctorData.subscription.toLowerCase() === 'plus') ? (
                            <Link to={`/doctor-results/${doctorData.id}/${doctorData.fullName?.replace(' ', '-')}/appointment-motive`} className="">
                              <div>
                                <span className="sm:block">
                                  <button
                                    type="button"
                                    className="custome-bg inline-flex items-center px-5 py-3 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-dl-primary-900 hover:bg-dl-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-dl-primary-700 focus:ring-dl-primary-700"
                                  >
                                    Reservar una cita
                                  </button>
                                </span>
                              </div>
                            </Link>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              {/* 
            <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
               <div className="mt-2 flex items-center text-sm text-gray-500">
                  <BriefcaseIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Full-time
               </div>
               <div className="mt-2 flex items-center text-sm text-gray-500">
                  <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Remote
               </div>
               <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CurrencyDollarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  $120k &ndash; $140k
               </div>
               <div className="mt-2 flex items-center text-sm text-gray-500">
                  <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Closing on January 9, 2020
               </div>
            </div>
            */}
            </div>
          </div>
        </header>
        <main className="pt-8 pb-16 min-h-[calc(100vh_-_306px)]">
          <div className="">
            {/* <div className="px-4 sm:px-0">
              {/*  <h2 className="text-lg font-medium text-dl-accent">  Datos generales </h2> */}
            {/* Tabs */}
            {/* <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="tabs"
                  name="tabs"
                  className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                  defaultValue={tabs.find((tab) => tab.current).name}
                  onChange={(e) => onTabSelected(e.target.value)}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name} value={tab.id}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <div className="border-b border-black">
                  <nav className="mt-2 -mb-px flex" aria-label="Tabs">
                    {tabs.map((tab, index) => (
                      <div
                        role="button"
                        tabIndex={index}
                        onClick={() => onTabSelected(tab.id)}
                        key={tab.name}
                        className={classNames('cursor-pointer', tab.current ? 'border-b-4 border-sky-500 text-sky-500 font-bold' : '  ', ' border-b border-black text-md px-7 py-2 ml-0 text-black')}
                      >
                        {tab.name}
                      </div>
                    ))}
                  </nav>
                </div>
              </div>
            </div> */}
            {/* <div className="flex flex-col gap-6 mt-6">{tabs.map((tab) => (tab.current ? tab.sections.map((section) => <ItemCard section={section} key={section.name} />) : null))}</div> */}
            <Tab.Group>
              <Tab.List className="tablist mt-10">
                <Tab className={handleTab}>Perfil</Tab>
                <Tab className={handleTab}>Horarios</Tab>
                <Tab className={handleTab}>Tarifas</Tab>
                <Tab className={handleTab}>Información</Tab>
                <Tab className={handleTab}>Servicios</Tab>
                <Tab className={handleTab}>Fotos</Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <Perfil doctorData={doctorData} />
                </Tab.Panel>
                <Tab.Panel>
                  <Horarios doctorData={doctorData} />
                </Tab.Panel>
                <Tab.Panel>
                  <Tarifas doctorData={doctorData} />
                </Tab.Panel>
                <Tab.Panel>
                  <Info doctorData={doctorData} />
                </Tab.Panel>
                <Tab.Panel>
                  <Services doctorData={doctorData} />
                </Tab.Panel>
                <Tab.Panel>
                  <Photos doctorData={doctorData} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </main>
      </div>
    </>
  );
}
