export default function FormInputField(props) {
    const {
        identifier,
        label,
        iconComponent,
        type,
        placeholder,
        helperText,
        registration,
        errorMessage,
        step,
        disabled,
        value,
        onKeyDown,
        onChange,
    } = props;

    return (
        <>
            <label htmlFor={identifier} className="block text-sm font-medium text-dl-accent mb-2 text-sm text-black">
                {label}
            </label>
            <div className="relative rounded-md ">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    {iconComponent}
                </div>
                <input
                    type={type}
                    name={identifier}
                    id={identifier}
                    step={step}
                    className={` block w-full border border-gray-300 rounded-full  py-2 px-3 
                    focus:outline-none focus:ring-dl-primary-400 focus:border-dl-primary-400 sm:text-sm font-normal h-10	 ${
                        iconComponent ? 'pl-10' : ''
                    }`}
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    {...registration}
                />
            </div>
            {!errorMessage && helperText && (
                <p className="mt-2 text-sm text-gray-500 font-normal text-right text-black" id={`${identifier}-description`}>
                    {helperText}
                </p>
            )}

            {errorMessage ? <p className="mt-2 text-sm text-red-700">{errorMessage}</p> : null}
        </>
    );
}
