import usePersonalProfile from '../../../personalProfile/hooks/usePersonalProfile';
import { Tab } from '@headlessui/react';
import OwnProfile from './profiletabs/OwnProfile';
import Languages from './profiletabs/Languages';
import Experience from './profiletabs/Experience';
import Education from './profiletabs/Education';
import Motives from './profiletabs/Motives';
import Schedules from './profiletabs/Schedules';
import Info from './profiletabs/Info';
import SecondarySpecialties from './profiletabs/SecondarySpecialties';
import Quotes from './profiletabs/Quotes';
import Services from './profiletabs/Services';

const PersonalInfo = () => {
  const { userId, userProfile, isLoading } = usePersonalProfile('doctor');

  const handleTab = ({ selected }) => (selected ? 'active' : ' outline-none');

  if (isLoading) return 'Loading';

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10 side-bar-right bg-dl-screen-lightblue ">
      <div className="">
        <div className="profile-top-content">
          <h4>Bienvenido Doctor</h4>
          <p>Ingresa la siguiente información para iniciar con tu perfil.</p>
          <p>Una vez guardado este registro podrás acceder a la información adicional e iniciar el proceso de validación de perfil.</p>
        </div>

        <Tab.Group>
          <Tab.List className="tablist mt-10">
            <Tab className={handleTab}>Perfil</Tab>
            <Tab className={handleTab}>Especialidades</Tab>
            <Tab className={handleTab}>Idioma</Tab>
            <Tab className={handleTab}>Experiencia</Tab>
            <Tab className={handleTab}>Educación</Tab>
            <Tab className={handleTab}>Motivos</Tab>
            <Tab className={handleTab}>Servicios</Tab>
            <Tab className={handleTab}>Horarios</Tab>
            <Tab className={handleTab}>Citas</Tab>
            <Tab className={handleTab}>Info</Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              {/* Perfil  */}
              <OwnProfile />
            </Tab.Panel>
            <Tab.Panel>
              {/* Especialidaded */}
              <SecondarySpecialties userProfile={userProfile}/>
            </Tab.Panel>
            {/* Idioma */}
            <Tab.Panel>
              <Languages basicProfile={userProfile} />
            </Tab.Panel>
            {/* Experincia */}
            <Tab.Panel>
              <Experience />
            </Tab.Panel>
            {/* Educacion */}
            <Tab.Panel>
              <Education />
            </Tab.Panel>
            {/* Motivos */}
            <Tab.Panel>
              <Motives />
            </Tab.Panel>
            <Tab.Panel>
              <Services />
            </Tab.Panel>
            {/* Horarios */}
            <Tab.Panel>
              <Schedules />
            </Tab.Panel>
            {/* Citas */}
            <Tab.Panel>
              <Quotes />
            </Tab.Panel>
            {/* Info */}
            <Tab.Panel>
              <Info />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
export default PersonalInfo;
