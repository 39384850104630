import React, { useState } from "react";

const Photos = ({ doctorData }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);

    const handleNext = () => {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % doctorData.office_pictures.length);
    };

    const handlePrev = () => {
        setSelectedIndex((prevIndex) => (prevIndex - 1 + doctorData.office_pictures.length) % doctorData.office_pictures.length);
    };

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <div className="flex flex-wrap gap-4 justify-center">
        {doctorData.office_pictures.slice(0, 10).map((image, index) => (
          <img
            key={index}
            src={process.env.REACT_APP_MEDIA_SERVER+'/'+image}
            alt={`Gallery ${index}`}
            className="w-48 h-48 object-cover rounded-lg cursor-pointer"
            onClick={() => setSelectedIndex(index)}
          />
        ))}
      </div>

      {selectedIndex !== null && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 p-4">
          <button onClick={handlePrev} className="absolute left-4 text-white text-3xl">❮</button>
          <img src={process.env.REACT_APP_MEDIA_SERVER+'/'+doctorData.office_pictures[selectedIndex]} alt="Selected" className="max-w-full max-h-full rounded-lg" />
          <button onClick={handleNext} className="absolute right-4 text-white text-3xl">❯</button>
          <button onClick={() => setSelectedIndex(null)} className="absolute top-4 right-4 text-white text-2xl">✖</button>
        </div>
      )}
    </div>
  );
};

export default Photos;
