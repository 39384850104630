import { CardWrap } from './DoctorResultsTabsElement';

const Services = ({ doctorData }) => {

  return (
      <CardWrap>
        <div className="px-4 py-4 sm:px-6 border-b divide-slate-200 border-black flex items-center  insurance-information-head">
          <h2 id="applicant-information-title" className="text-lg leading-6 font-bold text-black sm:px-6 border-red horarios-title">
            Servicios ofrecidos
          </h2>
        </div>
        <dl className="content-list-box flex items-center justify-between px-6 py-4">
          <div className="inner-content px-6">
            <ul className="list-disc pl-5">
                {doctorData.services.map((service, index) => (
                    <li key={index} className="inner-content px-6">
                    {service.name}
                    </li>
                ))}
            </ul>
          </div>
        </dl>
      </CardWrap>
  );
};
export default Services;
