import React from 'react';

const DeleteAccount = () => {
    return (
        <div className="static-page-des">
            <div className='wrapper-box'>
                <div className='bg-white rounded-lg px-10 pt-12 pb-10'>
                    <h2 className='text-center text-xl'><b>Delete Your Account</b></h2>
                    <p>If you wish to delete your Doctolink account, please review the information below.</p>
                    
                    <h3 className='text-lg'><b>Important Information Before Deleting</b></h3>
                    <ul>
                        <li>Deleting your account is permanent and cannot be undone.</li>
                        <li>All your personal data, appointments, and records will be erased from our system.</li>
                        <li>You will lose access to any active subscriptions and services linked to your account.</li>
                        <li>If you have any pending appointments or transactions, please resolve them before proceeding.</li>
                    </ul>
                    
                    <h3 className='text-lg'><b>How to Delete Your Account</b></h3>
                    <p>To delete your account, follow these steps:</p>
                    <ol>
                        <li>Log in to your <b>Doctolink account</b> on the <b>app</b>.</li>
                        <li>Navigate to <b>Info Tab</b>.</li>
                        <li>Scroll down and click on <b>Delete My Account</b>.</li>
                        <li>Follow the on-screen instructions to confirm the deletion.</li>
                    </ol>
                    <br />
                    <h3 className='text-lg'><b>Need Help?</b></h3>
                    <p>If you need assistance or have questions, contact our support team at <b>administracion@doctolinkapp.com</b>.</p>
                </div>
            </div>
        </div>
    );
};

export default DeleteAccount;