// import NavigationElements from './pageElements/NavigationElements';
// import useAuthentication from '../api/hooks/useAuthentication';

// export default function PlansPage({ displayNavigation, onUpdateDisplayNavigation }) {
//     const { submitContactUs } = useAuthentication();

//     const plans = [
//         {
//             name: 'Basic',
//             priceOld: '$240.00',
//             priceNew: '$150.00 + IVA',
//             features: [
//                 'Digitalízate y gana visibilidad en Doctolink',
//                 'Perfil profesional en directorio médico Doctolink',
//                 'Plataforma interactiva y editable',
//                 'Publicación de bienvenida en RRSS de Doctolink'
//             ],
//             color: 'bg-blue  text-blue-100',
//             isBasic: true // Add a flag to identify the Basic plan
//         },
//         {
//             name: 'Plus',
//             priceOld: '$300.00',
//             priceNew: '$225.00 + IVA',
//             features: [
//                 'Incluye todo lo del plan Basic',
//                 'Gestión agenda de citas y calendario online para el doctor',
//                 'Publicidad personalizada de sus servicios',
//                 'Almacenamiento en la nube de documentos',
//                 'Recordatorios vía email y WhatsApp'
//             ],
//             color: 'bg-blue  text-blue-100'
//         },
//         {
//             name: 'Pro',
//             priceOld: '$420.00',
//             priceNew: '$350.00 + IVA',
//             features: [
//                 'Incluye todo lo del plan Plus',
//                 'Automatización de gestión de citas 24/7 disponible para los pacientes',
//                 'Recordatorios de citas',
//                 'Integración y sincronización con Google Calendar',
//                 'Marketing digital',
//                 'Soporte Técnico personalizado'
//             ],
//             bestOffer: true,
//             color: 'bg-blue  text-blue-100'
//         }
//     ];

//     return (
//         <>
//             <NavigationElements
//                 displayNavigation={displayNavigation}
//                 onUpdateDisplayNavigation={onUpdateDisplayNavigation}
//             />
//             <div
//                 style={{ fontFamily: 'Gotham-Book', background: '#0b3e73' }}
//                 className="min-h-screen flex flex-col items-center px-6 py-12 text-white bg-gradient-to-b"
//             >
//                 <h2 style={{ fontFamily: 'Gotham-Book' }} className="text-4xl font-bold mb-8 text-center">
//                     Precios de LanZaniento - doctolink para Médicos
//                 </h2>
//                 <div className="grid md:grid-cols-3 gap-8  max-w-6xl w-full mt-6 ">
//                     {plans.map((plan, index) => (
//                         <div
//                             key={index}
//                             className={`relative p-8 rounded-3xl shadow-xl ${plan.color} text-white hover:scale-105 transition-transform duration-300 flex flex-col items-center`}
//                         >
//                             {plan.bestOffer && (
//                                 // #00b6fc
//                                 <div className="absolute -top-6 best-offer-label bg-[#00b6fc] text-white-900 font-bold px-4 py-1 transform rotate-12 shadow-md">
//                                     BEST OFFER
//                                 </div>
//                             )}
//                             <div className="absolute -top-6 flex justify-center">
//                                 <span className="bg-white h-10 text-blue-900 font-bold px-6 py-2 rounded-full text-lg shadow-md">
//                                     {plan.name}
//                                 </span>
//                             </div>
//                             <p className="text-center text-gray-300 line-through text-lg">{plan.priceOld}</p>
//                             <p className="text-center text-xl font-extrabold text-white-400">{plan.priceNew}</p>
//                             <ul className="mt-6 space-y-4 text-lg">
//                                 {plan.features.map((feature, i) => (
//                                     <li key={i} className="flex items-center gap-3">
//                                         <span className="text-white-400 font-extrabold text-center text-3xl">.</span>
//                                         <span>{feature}</span>
//                                     </li>
//                                 ))}
//                             </ul>
//                         </div>
//                     ))}
//                 </div>
//                 <p style={{ fontFamily: 'Gotham-Book' }} className="mt-8 text-lg text-gray-300">
//                     Accede desde cualquier dispositivo: celular, tablet, desktop
//                 </p>
//             </div>
//             <div
//                 style={{ background: '#0b3e73', fontFamily: 'Gotham-Book' }}
//                 className="min-h-screen flex flex-col items-center px-6 py-12  text-white"
//             >
//                 <h2 style={{ fontFamily: 'Gotham-Book' }} className="text-4xl font-bold mb-8 text-center">
//                     Funcionalidades y Cuadro Comparativo entre Planes
//                 </h2>
//                 <div className="overflow-x-auto w-full max-w-5xl">
//                     <table className="w-full border border-white text-left">
//                         <thead>
//                             <tr className="bg-blue-800 text-white">
//                                 <th style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Característica
//                                 </th>
//                                 <th style={{ background: '#62a2e0' }} className="p-4 border border-white">
//                                     Plan Basic
//                                 </th>
//                                 <th style={{ background: '#0b3e73' }} className="p-4 border border-white">
//                                     Plan Plus
//                                 </th>
//                                 <th style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Plan Pro
//                                 </th>
//                             </tr>
//                         </thead>
//                         <tbody className="bg-blue-700 text-gray-200">
//                             <tr>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Descripción
//                                 </td>
//                                 <td style={{ background: '#62a2e0' }} className="p-4 border border-white">
//                                     Digitalízate y gana visibilidad en Doctolink.
//                                 </td>
//                                 <td style={{ background: '#0b3e73' }} className="p-4 border border-white">
//                                     Incluye todo lo del plan básico.
//                                 </td>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Incluye todo lo del Plan Plus.
//                                 </td>
//                             </tr>
//                             <tr>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Recordatorios vía email y WhatsApp
//                                 </td>
//                                 <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
//                                     ✗
//                                 </td>
//                                 <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
//                                     ✔
//                                 </td>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
//                                     ✔
//                                 </td>
//                             </tr>
//                             <tr>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Automatización de citas 24/7 para pacientes
//                                 </td>
//                                 <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
//                                     ✗
//                                 </td>
//                                 <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
//                                     ✗
//                                 </td>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
//                                     ✔
//                                 </td>
//                             </tr>
//                             <tr>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Recordatorios de citas
//                                 </td>
//                                 <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
//                                     ✗
//                                 </td>
//                                 <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
//                                     ✔
//                                 </td>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
//                                     ✔
//                                 </td>
//                             </tr>
//                             <tr>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Integración con Google Calendar
//                                 </td>
//                                 <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
//                                     ✗
//                                 </td>
//                                 <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
//                                     ✗
//                                 </td>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
//                                     ✔
//                                 </td>
//                             </tr>
//                             <tr>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Marketing Digital
//                                 </td>
//                                 <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
//                                     ✗
//                                 </td>
//                                 <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
//                                     ✗
//                                 </td>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
//                                     Incluye 4 post mensual por cada médico
//                                 </td>
//                             </tr>
//                             <tr>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Soporte técnico
//                                 </td>
//                                 <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
//                                     ✗
//                                 </td>
//                                 <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
//                                     Básico
//                                 </td>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
//                                     Personalizado
//                                 </td>
//                             </tr>
//                             <tr className="bg-blue-800 font-bold">
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Precio (Anual + IVA)
//                                 </td>
//                                 <td style={{ background: '#62a2e0' }} className="p-4 border border-white">
//                                     Desde $150.00 (Normal $240.00)
//                                 </td>
//                                 <td style={{ background: '#0b3e73' }} className="p-4 border border-white">
//                                     Desde $225.00 (Normal $300.00)
//                                 </td>
//                                 <td style={{ background: '#065bb8' }} className="p-4 border border-white">
//                                     Desde $350.00 (Normal $420.00)
//                                 </td>
//                             </tr>
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </>
//     );
// }
import { useEffect, useState } from 'react';
import NavigationElements from './pageElements/NavigationElements';
import useAuthentication from '../api/hooks/useAuthentication';
import { plan } from '../v2/services/Plans';

export default function PlansPage({ displayNavigation, onUpdateDisplayNavigation }) {
    const { submitContactUs } = useAuthentication();
    const [plansList, setPlansList] = useState([]);
    const [characteristicsList, setCharacteristicsList] = useState([]);

    useEffect(() => {
        Promise.all([plan.get(), plan.getCharacteristics()])
            .then(([plansResponse, characteristicsResponse]) => {
                
                setPlansList(plansResponse.data);
                setCharacteristicsList(characteristicsResponse.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    return (
        <>
            <NavigationElements
                displayNavigation={displayNavigation}
                onUpdateDisplayNavigation={onUpdateDisplayNavigation}
            />
            <div
                style={{ fontFamily: 'Gotham-Book', background: '#0b3e73' }}
                className="min-h-screen flex flex-col items-center px-6 py-12 text-white bg-gradient-to-b"
            >
                <h2 style={{ fontFamily: 'Gotham-Book' }} className="text-4xl font-bold mb-8 text-center">
                    Planes doctolink para médicos - Anual
                </h2>
                <div className="grid md:grid-cols-3 gap-8  max-w-6xl w-full mt-6">
                    {plansList.map((plan, index) => (
                        <div
                            key={index}
                            className={`relative p-8 rounded-3xl shadow-x bg-blue text-blue-100 text-white hover:scale-105 transition-transform duration-300 flex flex-col items-center`}
                        >
                            <div className="absolute -top-6 flex justify-center">
                                <span className="bg-white h-10 text-blue-900 font-bold px-6 py-2 rounded-full text-lg shadow-md whitespace-pre">
                                    {plan.name}
                                </span>
                            </div>
                            <p className="text-center text-xl font-extrabold text-white-400">
                                <p className="text-white-100 text-sm">${plan.priceAnnual}</p>${plan.priceIva} + IVA
                            </p>
                            <ul className="mt-6 space-y-4 text-lg">
                                {plan.description.split(/<\/p>\s*<p>/).map((desc, i) => (
                                    <li key={i} className="flex items-center gap-3">
                                        <span className="text-white-400 font-extrabold text-center text-3xl">•</span>
                                        <span dangerouslySetInnerHTML={{ __html: desc.replace(/<\/?p>/g, '') }} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                <p style={{ fontFamily: 'Gotham-Book' }} className="mt-8 text-lg text-gray-300">
                    Accede desde cualquier dispositivo: celular, tablet, desktop
                </p>
            </div>
            <div
                style={{ background: '#0b3e73', fontFamily: 'Gotham-Book' }}
                className="min-h-screen flex flex-col items-center px-6 py-12  text-white"
            >
                <h2 style={{ fontFamily: 'Gotham-Book' }} className="text-4xl font-bold mb-8 text-center">
                    Funcionalidades y Cuadro Comparativo entre Planes
                </h2>
                <div className="overflow-x-auto w-full max-w-5xl">
                        {/* <table className="w-full border border-white text-left">
                            <thead>
                                <tr className="bg-blue-800 text-white">
                                    <th style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Característica
                                    </th>
                                    <th style={{ background: '#62a2e0' }} className="p-4 border border-white">
                                        Plan Basic
                                    </th>
                                    <th style={{ background: '#0b3e73' }} className="p-4 border border-white">
                                        Plan Plus
                                    </th>
                                    <th style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Plan Pro
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-blue-700 text-gray-200">
                                <tr>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Descripción
                                    </td>
                                    <td style={{ background: '#62a2e0' }} className="p-4 border border-white">
                                        Digitalízate y gana visibilidad en Doctolink.
                                    </td>
                                    <td style={{ background: '#0b3e73' }} className="p-4 border border-white">
                                        Incluye todo lo del plan básico.
                                    </td>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Incluye todo lo del Plan Plus.
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Recordatorios vía email y WhatsApp
                                    </td>
                                    <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
                                        ✗
                                    </td>
                                    <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
                                        ✔
                                    </td>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
                                        ✔
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Automatización de citas 24/7 para pacientes
                                    </td>
                                    <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
                                        ✗
                                    </td>
                                    <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
                                        ✗
                                    </td>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
                                        ✔
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Recordatorios de citas
                                    </td>
                                    <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
                                        ✗
                                    </td>
                                    <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
                                        ✔
                                    </td>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
                                        ✔
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Integración con Google Calendar
                                    </td>
                                    <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
                                        ✗
                                    </td>
                                    <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
                                        ✗
                                    </td>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
                                        ✔
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Marketing Digital
                                    </td>
                                    <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
                                        ✗
                                    </td>
                                    <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
                                        ✗
                                    </td>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
                                        Incluye 4 post mensual por cada médico
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Soporte técnico
                                    </td>
                                    <td style={{ background: '#62a2e0' }} className="p-4 border border-white text-center">
                                        ✗
                                    </td>
                                    <td style={{ background: '#0b3e73' }} className="p-4 border border-white text-center">
                                        Básico
                                    </td>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white text-center">
                                        Personalizado
                                    </td>
                                </tr>
                                <tr className="bg-blue-800 font-bold">
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Precio (Anual + IVA)
                                    </td>
                                    <td style={{ background: '#62a2e0' }} className="p-4 border border-white">
                                        Desde $150.00 (Normal $240.00)
                                    </td>
                                    <td style={{ background: '#0b3e73' }} className="p-4 border border-white">
                                        Desde $225.00 (Normal $300.00)
                                    </td>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        Desde $350.00 (Normal $420.00)
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}
                    <table className="w-full border-collapse border border-white">
                        <thead>
                            <tr className="text-white">
                                <th className="p-4 border border-white">Característica</th>
                                {plansList.map((plan, index) => (
                                    <th key={index} className="p-4 border border-white">{plan.name}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className=" text-gray-200">
                            {characteristicsList ? 
                                characteristicsList.map((characteristic) => (
                                <tr key={characteristic.id}>
                                    <td style={{ background: '#065bb8' }} className="p-4 border border-white">
                                        {characteristic.name}
                                    </td>
                                    {plansList.map((plan, index) => {
                                        const enabledPan = plan.characteristics?.find(
                                            (item) => parseInt(item.id) === characteristic.id
                                        );
                                        
                                        return (
                                            <td
                                                key={index}
                                                style={{ background: index % 2 === 0 ? '#62a2e0' : '#0b3e73' }}
                                                className="p-4 border border-white text-center"
                                            >
                                                {enabledPan ? '✔' : '✗'}
                                            </td>
                                        );
                                    })}
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
