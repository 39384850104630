import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Documentos } from '../../../v2/services/Documentos';
import CustomSuccessModal from '../customSuccessModal/CustomSuccessModal';
import { format, parse } from 'date-fns';

export default function AddDocDialog({ open, setClose, title, bodyText, categories, actionButtonText, actionButtonFunction, userInfo, setShowSuccessModal, setRefresh, refresh, appointmentId }) {
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [maxLimit, setMaxLimit] = useState(1);
  const [documentName, setDocumentName] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [selectedAppointment, setSelectedAppointment] = useState("")
  const [allAppointments, setAllAppointments] = useState([])
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(
    {
      documentName: 'Document name is required.',
      doctorName: 'Doctor name is required.',
      idCategory: 'Please select a category.',
      // idAppointment: "Please select appointment"
      // document_file: 'Please select a file.'
    }
  );
  const [res, setRes] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const formData = new FormData();

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Changed to Array.from

    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

    const validFiles = files.filter(file => allowedTypes.includes(file.type));
    const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));


    if (invalidFiles?.length > 0) {
      setRes("Only the following formats are accepted : JPEG, PNG, JPG, PDF.")
    }


    const totalFiles = (validFiles?.length || 0) + (selectedFile?.length || 0)
    console.log(selectedFile, validFiles, 'file(s) to add');
    setSelectedFile([...selectedFile, ...validFiles])
    if (selectedCategory) {
      fileValidation(totalFiles, maxLimit, selectedCategory)
    }
    e.target.value = null;

  };

  const fileValidation = (files, maxFiles, categoryId) => {

    if (files == 0) {
      setError({ ...error, idCategory: categoryId ? "" : "Please select a category", document_file: 'Please select a file.' });
    }
    if (files > 0 && files <= maxFiles) {
      setError({ ...error, idCategory: categoryId ? "" : "Please select a category", document_file: '' });
    }
    else if (files > maxFiles) {
      setError({ ...error, idCategory: categoryId ? "" : "Please select a category", document_file: `Maximum ${maxFiles} ${maxFiles < 2 ? "file" : "files"} can be uploaded` });
    }
  }

  const handleInputChange = (e, fieldKey, fieldName) => {
    setError({ ...error, [fieldKey]: e.target.value.trim() ? '' : `${fieldName} is required.` });

    if (fieldKey === 'documentName') {
      setDocumentName(e.target.value);
    } else {
      setDoctorName(e.target.value);
    }
  }

  const handleCategoryChange = (e) => {
    setRes('');
    const categoryId = e.target.value;
    setSelectedCategory(categoryId);
    if (!categoryId) {
      setError(
        {
          ...error,
          idCategory: 'Please select a category.',
          document_file: ""
        });
    }
    else {
      const selectedCategoryObj = categories.find((category) => category.id == categoryId);
      if (selectedCategoryObj) {
        setMaxLimit(selectedCategoryObj.maxLimit);
        fileValidation(selectedFile?.length || 0, selectedCategoryObj.maxLimit, categoryId)
      } else {
      }
    }



  };

  const handleAppointmentChange = (e) => {
    setRes('');
    const appointmentId = e.target.value
    setSelectedAppointment(appointmentId)
    // if (!appointmentId) {
    //   setError({ ...error, idAppointment: "Please select appointment" })
    // } else {
    //   setError({ ...error, idAppointment: "" })

    // }
  }


  const handleSubmit = async () => {
    setSubmitted(true);
    if (Object.values(error).some(item => item.length)) return;

    setLoader(true)

    formData.set('name', documentName.trim());
    formData.set('doctor_name', doctorName.trim());
    formData.set('idCategory', Number(selectedCategory));
    // formData.set('document_file', selectedFile);
    formData.set('idUser', userInfo.id);
    formData.set('userType', userInfo.usertype);
    formData.append("idAppointment", selectedAppointment)
    selectedFile?.map((file, index) => {
      formData.append(`document_file`, file)
    })

    if (userInfo.usertype === 2) {
      formData.set('idPatient', userInfo.idPatient);
    }

    try {

      const response = await Documentos.add(formData);
      if (response) {
        actionButtonFunction(selectedFile, selectedCategory, documentName, doctorName);
        setError('');
        setClose(false);
        setShowSuccessModal(true);
        setRefresh(!refresh);
      }
    } catch (err) {
      console.log(err);
      setRes(err.response.data.message);
    }
    finally {
      setLoader(false)
    }
  };
  useEffect(() => {
    return () => {
      setDocumentName('');
      setDoctorName('');
      setSelectedCategory('');
      setSelectedCategory('');
      setSelectedFile([]);
      setSelectedAppointment("")
      //   setShowSuccessModal(false);
    };
  }, []);

  const handleRemove = (index) => {
    const filterFiles = selectedFile?.filter((file, id) => id != index)
    setSelectedFile(filterFiles)
    fileValidation(filterFiles.length, maxLimit, selectedCategory)
  }

  const getUserAppointmentList = async () => {
    try {
      const res = await Documentos.getAppointmentHistory()
      let modifiedPastData = res?.data?.completed?.map((d) => ({ ...d, appointmentID: d?.apptID }))
      setAllAppointments([...modifiedPastData, ...res?.data?.pending])
      // setAllAppointments past pending
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!appointmentId) {
      getUserAppointmentList()
    } else {
      setSelectedAppointment(appointmentId)
      // setError({ ...error, idAppointment: "" })
    }
  }, [])

  const formatDate = (date, time) => {
    if (!date || !time) return;
    const dateTimeString = `${date}T${time}`;
    const dateTime = new Date(dateTimeString);
    const formattedDateTime = format(dateTime, 'MM/dd/yyyy HH:mm:a');
    return formattedDateTime
  }

  useEffect(() => {
    let errorTimer;
    if (res?.length > 0) {
      errorTimer = setTimeout(() => {
        setRes("");
      }, 5000);
    }

    // Cleanup function to clear the timeout
    return () => {
      if (errorTimer) {
        clearTimeout(errorTimer);
      }
    };
  }, [res]);


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setClose}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{bodyText}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <input
                    type="text"
                    value={documentName}
                    onChange={(e) => handleInputChange(e, 'documentName', 'Document name')}
                    placeholder="Document Name"
                    className="block w-full text-sm text-gray-500 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  {(error.documentName && submitted) && <div className="mt-2 text-sm text-red-600">{error.documentName}</div>}
                </div>
                <div className="mt-5 sm:mt-6">
                  <input
                    type="text"
                    value={doctorName}
                    onChange={(e) => handleInputChange(e, 'doctorName', 'Doctor name')}
                    placeholder="Doctor Name"
                    className="block w-full text-sm text-gray-500 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  {(error.doctorName && submitted) && <div className="mt-2 text-sm text-red-600">{error.doctorName}</div>}
                </div>

                {!appointmentId ?
                  <div className="mt-5 sm:mt-6">
                    <select
                      value={selectedAppointment}
                      onChange={handleAppointmentChange}
                      className="block w-full mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option key="0" value="">Select appointment</option>
                      {allAppointments.map((appoint) => (
                        <option key={appoint.appointmentID} value={appoint.appointmentID}>
                          {`${appoint?.doctorFullName} - ${formatDate(appoint?.appDate, appoint?.appTime)}`}
                        </option>
                      ))}
                    </select>
                    {/* {(error.idAppointment && submitted) && <div className="mt-2 text-sm text-red-600">{error.idAppointment}</div>} */}
                  </div>
                  : ""
                }

                <div className="mt-5 sm:mt-6">
                  <select value={selectedCategory} onChange={handleCategoryChange} className="block w-full mt-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    <option key="0" value="">Select category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  {(error.idCategory && submitted) && <div className="mt-2 text-sm text-red-600">{error.idCategory}</div>}
                </div>
                <div className="mt-5 sm:mt-6">
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    onChange={handleFileChange}
                    multiple
                    onClick={(e) => (e.target.value = selectedFile?.name ?? '')}
                    className="block w-[100px] text-sm text-gray-500 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />

                  <div>
                    {selectedFile?.map((file, index) => (
                      <div className="relative inline-block w-[160px] truncate overflow-hidden bg-gray-100 py-2 px-2  my-1 mr-2">
                        <div className="flex justify-center items-center gap-2">
                          <div className="h-[30px] w-[30px] rounded-full overflow-hidden flex-shrink-0">
                            {file?.type == "application/pdf"
                              ? <img src={"/defaultIcons/pdf.png "} alt="image" className="h-full w-full object-cover" />
                              : <img src={URL.createObjectURL(file)} alt="image" className="h-full w-full object-cover" />
                            }
                          </div>
                          <span key={index} title={file.name} className=' truncate'>{file.name}</span>
                          <small className='absolute top-0 right-1  text-red-500 cursor-pointer font-bold' onClick={() => handleRemove(index)}>x</small>
                        </div>
                      </div>

                    ))}
                  </div>


                  {(error.document_file && submitted) && <div className="mt-2 text-sm text-red-600">{error.document_file}</div>}
                  {/* {selectedFile && <p className="mt-2 text-sm text-gray-500">{selectedFile.length} files selected</p>} */}

                </div>

                {res && <div className="mt-2 text-sm text-red-600">{res}</div>}

                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 ${loader && "bg-gray-400 hover:bg-gray-400 "} px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm `}
                    onClick={handleSubmit}
                    disabled={loader}
                  >
                    {actionButtonText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
