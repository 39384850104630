import React, { useEffect, useState } from 'react';
import ProfileFormHeader from '../../../../doctorUser/components/ProfileFormHeader/ProfileFormHeader';
import { CxCard, CxWrap, Line } from '../../../components/Sx';
import HalfRow from '../../../../common/components/formElements/HalfRow';
import FormInputField from '../../../../common/components/formElements/FormInputField';
import { useForm } from 'react-hook-form';
import CustomRedAlert from '../../../../common/components/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../common/components/customSuccessModal/CustomSuccessModal';
import { ServicesS } from '../../../services/DoctorProfile';

const Services = () => {
    const [inputValue, setInputValue] = useState('');
    const [services, setServices] = useState([]);
    const [successBody, setSuccessBody] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorBody, setErrorBody] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const { register, handleSubmit, formState, watch, reset, setValue, getValues, setError } = useForm();

    const [refresh, setRefresh] = useState(false);

    const closeErrorMessage = () => {
        setShowErrorModal(false);
        setErrorBody('');
    };
    
    const _createErrorMessage = (errorMessage) => {
        setShowErrorModal(true);
        setErrorBody(errorMessage);
    };

    const onCloseSuccessMessage = () => {
        setShowSuccessModal(false);
        setSuccessBody('');
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const _createSuccessMessage = (message) => {
        setShowSuccessModal(true);
        setSuccessBody(message);
    };

    const handleAddService = () => {
        if (inputValue.trim()) {
            ServicesS.createServices({ name: inputValue })
            .then(() => _createSuccessMessage('Servicio agregado para perfil.'))
            .then(() => {
                setRefresh(!refresh);
                setInputValue('');
            })
            .catch((err) => console.log(err));
        }
    };

    const handleDeleteService = (id) => {
        if (id) {
            ServicesS.deleteServices(id)
            .then(() => _createSuccessMessage('Servicio eliminado del perfil.'))
            .then(() => setRefresh(!refresh))
            .catch((err) => console.log(err));
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleAddService();
        }
    };

    const fxServices = async () => {
        try {
          const response = await ServicesS.getAllServices();
          setServices(response.data);
        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        fxServices();
    }, [refresh]);

    return (
        <form>
            <CxWrap>
                <CxCard>
                <ProfileFormHeader title={'Servicios ofrecidos'} initialText={`Por favor, ingrese los servicios que ofrece. Escriba el servicio en el campo de entrada y presione Enter o haga clic en "Agregar Servicio" para añadirlo a la lista.`}  />
                </CxCard>
                <Line />
                <CxCard>
                <div className="grid grid-cols-12 gap-4 pb-8 px-4">
                <HalfRow>
                        <FormInputField
                            identifier={'service'}
                            label={'Servicio'}
                            type={'text'}
                            placeholder={'Ingrese el nombre del servicio'}
                            helperText={'Requerido'}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            value={inputValue}
                        />
                    </HalfRow>
                </div>
            
                </CxCard>
                </CxWrap>
                <div className="flex flex-wrap pt-10 pl-12">
                    {
                        services.map((service, index) => (
                            <div class="py-2 px-8 !w-auto  flex justify-between  items-center gap-2 shadow-md no-underline rounded-full light-bg-blue text-white font-sans font-semibold text-sm border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none mr-2 mb-5">
                                {service.name}
                            <span onClick={() => handleDeleteService(service.id)} className="cursor-pointer pl-3 text-xl text-gray-500 font-bold">x</span>
                            </div>
                        ))
                    }
                
                </div>

            {showSuccessModal && <CustomSuccessModal title={'Acción ejecutada con éxito!'} bodyText={successBody} buttonText={'Cerrar'} buttonAction={onCloseSuccessMessage} open={showSuccessModal} onClose={onCloseSuccessMessage} />}
            {showErrorModal && <CustomRedAlert open={true} title={'Ocurrió un error.'} bodyText={errorBody} onClose={closeErrorMessage} />}
        </form>
    );
};

export default Services;